import React, { useContext } from "react";

import { useLocation } from "@reach/router";
import classNames from "classnames";

import { ExpertState } from "@/autoGeneratedGlobalTypes";
import ExpertCard from "@/components/common/expertCard";
import Paging from "@/components/common/paging";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { isCatalogBannerActive } from "@/utils/bannerUtils";
import { sortExpertEdgesByStatus } from "@/utils/commonUtils";
import { PAGE_SIZE } from "@components/catalog/catalogContainer/constants";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";

import { ReservedFiltersEnum } from "../../types";
import { urlObserver } from "../../utils/urlObserver";
import ExpertListingBanner from "../expertListingBanner";

import Skeleton from "./skeleton";
import { ExpertListProps } from "./types";

import "./styles.scss";

const ExpertList = (props: ExpertListProps) => {
  const {
    experts,
    isLoading,
    isMoreLoading,
    onChange,
    onLoadMoreClick,
    sorting,
    catalogBannerData,
  } = props;

  const { isUserLoggedIn } = useContext<UserContextType>(UserContext);

  const currentPage = urlObserver.getState()[ReservedFiltersEnum.Page]?.[0] ?? 1;
  const remainingCount = experts.totalCount > currentPage * PAGE_SIZE ? experts.totalCount - currentPage * PAGE_SIZE : 0;
  const location = useLocation();
  // todo: make correct sorting on backand and use just experts?.edges
  const dirtyListing = sortExpertEdgesByStatus(experts?.edges, sorting);

  const availableExperts = dirtyListing.filter(
    (expert) =>
      expert?.node.state === ExpertState.available,
  );
  const nonAvailableExperts = dirtyListing.splice(availableExperts.length);

  const expertCardClassName = `expert-list__item ${
    location?.state?.onboardingFlag ? "expert-list__onboarding-item" : ""
  }`;

  const expertListingBannerIsShown = isCatalogBannerActive(
    catalogBannerData.catalogBannerIsShown,
    isUserLoggedIn,
    catalogBannerData.catalogBannerIsShownOnlyForAuthorized,
    catalogBannerData.catalogBannerShowOnProdFlag,
  );

  return (
    <div className="expert-list">
      {!isLoading && (
        <>
          {availableExperts.map((edge: any, index: number) =>
            (
              <div
                className={classNames(expertCardClassName, {
                  "expert-list__item--no-border-after":
                  expertListingBannerIsShown && index === availableExperts.length - 1,
                })}
                key={edge.node.id}
              >
                <ExpertCard expert={edge.node} expertCardPosition={index} />
              </div>
            ))}
          {expertListingBannerIsShown && (
            <ExpertListingBanner catalogBannerData={catalogBannerData} />
          )}
          {nonAvailableExperts.map((edge: any, index: number) =>
            (
              <div className={expertCardClassName} key={edge.node.id}>
                <ExpertCard expert={edge.node} expertCardPosition={index} />
              </div>
            ))}
        </>
      )}
      {(isLoading || isMoreLoading)
        && Array(remainingCount ? Math.min(remainingCount, PAGE_SIZE) : PAGE_SIZE)
          .fill("")
          .map((_, index) =>
            (
              <div className="expert-list__item" key={index}>
                <Skeleton />
              </div>
            ))}
      {remainingCount > 0 && experts?.pageInfo.hasNextPage && (
        <Button
          text={`Показать ещё ${Math.min(remainingCount, PAGE_SIZE)}`}
          size={ButtonSizeEnum.Middle}
          color={ButtonColorEnum.Light}
          className="expert-list__more"
          onClick={() =>
            onLoadMoreClick()}
          isLoading={isLoading}
        />
      )}
      <Paging
        currentPage={urlObserver.getState()[ReservedFiltersEnum.Page]?.[0] ?? 1}
        totalCount={experts.totalCount}
        pageSize={PAGE_SIZE}
        onChange={(newPageNumber) =>
          onChange(ReservedFiltersEnum.Page, [newPageNumber])}
      />
    </div>
  );
};

export default ExpertList;
